import { Month } from "@gocardless/api/dashboard/types";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export const monthToName = (): Record<Month, string> => ({
  [Month.January]: i18n._(t({ id: "January", message: "January" })),
  [Month.February]: i18n._(t({ id: "February", message: "February" })),
  [Month.March]: i18n._(t({ id: "March", message: "March" })),
  [Month.April]: i18n._(t({ id: "April", message: "April" })),
  [Month.May]: i18n._(t({ id: "May", message: "May" })),
  [Month.June]: i18n._(t({ id: "June", message: "June" })),
  [Month.July]: i18n._(t({ id: "July", message: "July" })),
  [Month.August]: i18n._(t({ id: "August", message: "August" })),
  [Month.September]: i18n._(t({ id: "September", message: "September" })),
  [Month.October]: i18n._(t({ id: "October", message: "October" })),
  [Month.November]: i18n._(t({ id: "November", message: "November" })),
  [Month.December]: i18n._(t({ id: "December", message: "December" })),
});
