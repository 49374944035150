import _ from "lodash";
import { Hosts, host, isProd } from "src/common/hosts";

enum CookieNames {
  Dev = "gc_ramltoolkit_id_merchant_development_live",
  Staging = "gc_ramltoolkit_id_merchant_staging_live",
  Prod = "gc_ramltoolkit_id_merchant_production_live",
}

const mapping = {
  [Hosts.Dev]: CookieNames.Dev,
  [Hosts.Staging]: CookieNames.Staging,
  [Hosts.Prod]: CookieNames.Prod,
};

const name = _.get(mapping, host, undefined);

export const THREAT_METRIX_MERCHANT_ORG_ID = isProd ? "cpuoahbo" : "6pst3iiy";
export const SESSION_COOKIE_NAME = _.isUndefined(name) ? CookieNames.Dev : name;
export const BLOCK_MERCHANT_PROFILING = "block_merchant_profiling";
