import { ramltoolkit } from "./ramltoolkit";

export const useThreatMetrix = (
  id: string | undefined,
  threatMetrixMerchantOrgId: string,
  markAsProfiled: (id: string) => void
) => ({
  startProfiling: () => {
    if (id) {
      ramltoolkit.action("", threatMetrixMerchantOrgId, id);
      markAsProfiled(id);
    }
  },
});
