import { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

export const idempotencyKeyHeaderKey = "Idempotency-Key";

export const useIdempotencyKeyHeader = () => {
  const idempotencyKey = useRef("");

  useEffect(() => {
    idempotencyKey.current = uuidv4();
  }, []);

  return idempotencyKey.current
    ? { [idempotencyKeyHeaderKey]: idempotencyKey.current }
    : undefined;
};
