// This is an external script provided by our third party provider
// A number TS and ESLint errors are supressed for now until we find
// a better workflow for managing this src.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
export const ramltoolkit =
  typeof window !== "undefined" ? window.ramltoolkit || {} : {};
(ramltoolkit.version = 3),
  (ramltoolkit.create_url = function (t, e, r, n, a) {
    function i() {
      return Math.floor(2742745743359 * Math.random());
    }
    function c() {
      return o(i());
    }
    function o(t) {
      return (t + 78364164096).toString(36);
    }
    let m = i();

    let u = i();

    let l = 885187064159;
    (u = ((u = u - (u % 256) + ramltoolkit.version) + m) % 2742745743359),
      (l = (l + m) % 2742745743359);
    const rhino = "https://rhino.gocardless.com/";
    const s = `${rhino}${(m = c() + o(m))}${e}`;

    const h = [`${(u = o(l) + o(u))}=${r}`, `${c() + c()}=${n}`];
    return (
      void 0 !== a && a.length > 0 && h.push(`${c() + c()}=${a}`),
      `${s}?${h.join("&")}`
    );
  }),
  (ramltoolkit.beacon = function (t, e, r, n) {
    const a = "turn:aa.online-metrix.net?transport=";

    const i = `1:${e}:${r}`;

    const c = {
      iceServers: [
        { urls: `${a}tcp`, username: i, credential: r },
        { urls: `${a}udp`, username: i, credential: r },
      ],
    };
    try {
      const o = new RTCPeerConnection(c);
      o.createDataChannel(Math.random().toString());
      const m = function () {};
      const u = function (t) {
        o.setLocalDescription(t, m, m);
      };
      typeof Promise === "undefined" || o.createOffer.length > 0
        ? o.createOffer(u, m)
        : o.createOffer().then(u, m);
    } catch (t) {}
  }),
  (ramltoolkit.load_tags = function (t, e, r, n) {
    ramltoolkit.beacon(t, e, r, n);
    const a = document.getElementsByTagName("head").item(0);

    const i = document.createElement("script");
    (i.id = "tmx_tags_js"), i.setAttribute("type", "text/javascript");
    const c = ramltoolkit.create_url(t, ".js", e, r, n);
    i.setAttribute("src", c), ramltoolkit.set_csp_nonce(i), a.appendChild(i);
  }),
  (ramltoolkit.load_iframe_tags = function (t, e, r, n) {
    ramltoolkit.beacon(t, e, r, n);
    const a = ramltoolkit.create_url(t, ".htm", e, r, n);

    const i = document.createElement("iframe");
    (i.title = "empty"),
      i.setAttribute("aria-disabled", "true"),
      (i.width = "0"),
      (i.height = "0"),
      i.setAttribute(
        "style",
        "color:rgba(0,0,0,0); float:left; position:absolute; top:-200; left:-200; border:0px"
      ),
      i.setAttribute("src", a),
      document.body.appendChild(i);
  }),
  (ramltoolkit.csp_nonce = null),
  (ramltoolkit.register_csp_nonce = function (t) {
    if (void 0 !== t.currentScript && t.currentScript !== null) {
      const e = t.currentScript.getAttribute("nonce");
      void 0 !== e && e !== null && e !== ""
        ? (ramltoolkit.csp_nonce = e)
        : void 0 !== t.currentScript.nonce &&
          t.currentScript.nonce !== null &&
          t.currentScript.nonce !== "" &&
          (ramltoolkit.csp_nonce = t.currentScript.nonce);
    }
  }),
  (ramltoolkit.set_csp_nonce = function (t) {
    ramltoolkit.csp_nonce !== null &&
      (t.setAttribute("nonce", ramltoolkit.csp_nonce),
      t.getAttribute("nonce") !== ramltoolkit.csp_nonce &&
        (t.nonce = ramltoolkit.csp_nonce));
  }),
  (ramltoolkit.cleanup = function () {
    let hp_frame, tmx_frame, tmx_script;
    for (; (hp_frame = document.getElementById("tdz_ifrm")) !== null; ) {
      hp_frame.parentElement?.removeChild(hp_frame);
    }
    for (
      ;
      (tmx_frame = document.getElementById("tmx_tags_iframe")) !== null;

    ) {
      tmx_frame.parentElement?.removeChild(tmx_frame);
    }
    for (; (tmx_script = document.getElementById("tmx_tags_js")) !== null; ) {
      tmx_script.parentElement?.removeChild(tmx_script);
    }
  }),
  (ramltoolkit.action = function (t, e, r, n) {
    ramltoolkit.cleanup(),
      ramltoolkit.register_csp_nonce(document),
      ramltoolkit.load_tags(t, e, r, n);
  }),
  (ramltoolkit.profile_iframe = function (t, e, r, n) {
    ramltoolkit.cleanup(),
      ramltoolkit.register_csp_nonce(document),
      ramltoolkit.load_iframe_tags(t, e, r, n);
  });

export default ramltoolkit;
